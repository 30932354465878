export class DisplayConfig {
    
    ciBoardColumns: number;
    ciBoardRows: number;    
    
    constructor (ciBoardColumns: number, ciBoardRows: number) {
        this.ciBoardColumns = ciBoardColumns;        
        this.ciBoardRows = ciBoardRows;
    }

}